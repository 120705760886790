<template>
  <div class="yl-picker">
    <div class="icon">
      <slot></slot>
    </div>
    <div class="select" @click="isVisible = true" ref="select">
      <div class="placeholder" v-if="data == ''">{{ placeholder }}</div>
      {{ data }}
    </div>
    <div class="right-icon">
      <img
        src="../assets/bottom.png"
        alt=""
        oncontextmenu="return false;"
        ondragstart="return false;"
      />
    </div>
    <nut-picker
      v-if="!multiple"
      :is-visible="isVisible"
      :list-data="dataList"
      @close="isVisible = false"
      @confirm="confirmHander"
    ></nut-picker>
    <div v-if="multiple">
      <div
        :class="['multiple', isVisible ? 'active' : '']"
        v-show="isVisible"
        ref="multiple"
        @click="closePanel()"
      ></div>
      <div :class="['wrap', isVisible ? 'active' : '']">
        <div class="top-bar">
          <button @click="closePanel()">取 消</button>
          <button @click="confirm()">确 定</button>
        </div>
        <ul class="content">
          <li
            v-for="(item, i) in dataList"
            :key="i"
            :class="tempData.indexOf(item) != -1 ? 'active' : ''"
            @click="selectData(item)"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Picker from "@nutui/nutui/dist/packages/picker/picker.js";
import "@nutui/nutui/dist/packages/picker/picker.css";
Picker.install(Vue);
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    dataList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: false,
      data: this.value,
      tempData: this.value,
    };
  },
  watch: {
    value(newVal) {
      this.data = newVal;
    },
    data(newVal) {
      this.$emit("input", newVal);
    },
    isVisible() {
      if (this.isVisible) {
        console.log(Date.now());
      }
    },
  },
  methods: {
    confirmHander(val) {
      this.data = val[0].value;
    },
    closePanel() {
      this.isVisible = false;
      this.tempData = this.data;
    },
    selectData(val) {
      let arr = this.tempData == "" ? [] : this.tempData.split(" | ");
      if (this.tempData.indexOf(val) != -1) {
        //   当前已选择
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] == val) {
            arr.splice(i, 1);
          }
        }
      } else {
        //   未选择 把数据加进去
        arr.push(val);
      }
      this.tempData = arr.join(" | ");
    },
    confirm() {
      this.data = this.tempData;
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.yl-picker {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  height: 40px;
  border: 1px solid #f8f5f1;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  font-size: 14px;
  .select {
    height: 100%;
    line-height: 40px;
    margin: 0 40px;
    white-space: nowrap;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .select::-webkit-scrollbar {
    height: 0 !important;
  }
  .placeholder {
    color: #b1b1b1;
  }
  .icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      max-height: 50%;
      max-width: 50%;
    }
  }
  .right-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      max-height: 50%;
      max-width: 50%;
    }
  }
  .multiple {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 2098;
    position: fixed;
    top: 0;
    left: 0;
  }
  .multiple.active {
    background-color: rgba(0, 0, 0, 0.4);
  }
  .wrap {
    width: 100%;
    height: 293px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 3098;
    transition: all 0.3s ease-in-out;
    transform: translateY(100%);
    background-color: #fff;
    font-size: 14px;
    .top-bar {
      height: 40px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f6f6f6;
      button {
        width: 60px;
        height: 100%;
        border: none;
        background-color: #fff;
        color: #f0250f;
      }
    }
    .content {
      list-style: none;
      margin: 0;
      padding: 0;
      overflow-x: hidden;
      overflow-y: scroll;
      height: 252px;
      li {
        height: 40px;
        border-bottom: 1px solid #f6f6f6;
        line-height: 40px;
        text-align: center;
      }
      li:last-child {
        border-bottom: none;
      }
      li.active {
        color: #409eff;
        font-weight: 600;
      }
    }
  }
  .wrap.active {
    transform: translateY(0);
  }
}
</style>
